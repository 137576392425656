
.footer-title {
  font-size: 20px;
  font-family: montserrat-regular;
  color: #2F2E2E;
}

.footer-text-head {
  font-size: 25px;
  font-family: montserrat-medium;
  color: #6C6A6A;
  margin-top: 6px;
}
.footer-text-small {
  font-size: 20px;
  font-family: montserrat-regular;
  color: #2F2E2E;
  margin-top: 6px;
}

.terms-head {
  font-size: 20px;
  font-family: montserrat-bold;
  margin-top: 6px;
}