@font-face {
  font-family: "montserrat-bold";
  src: url(./fonts/montserrat_bold.ttf) format("truetype");
}
@font-face {
  font-family: "montserrat-semibold";
  src: url(./fonts/montserrat_semi_bold.ttf) format("truetype");
}
@font-face {
  font-family: "montserrat-medium";
  src: url(./fonts/montserrat_medium.ttf) format("truetype");
}

@font-face {
  font-family: "montserrat-regular";
  src: url(./fonts/montserrat_regular.ttf) format("truetype");
}

@font-face {
  font-family: "montserrat";
  src: url(./fonts/montserrat_regular.ttf) format("truetype");
}

body {
  margin: 0;
  background-color: #F2F2F2;
}
